<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div v-if="categories !== null">
      <!-- Table Container Card -->
      <b-card class="mb-0">
        <h1>دسته ها</h1>
        <div class="my-2">
          <!-- Table Top -->
          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-start mb-1"
            >
              <b-button
                variant="primary"
                @click="addNewCategory = !addNewCategory"
              >
                <span class="text-nowrap">+ ساخت دسته بندی جدید</span>
              </b-button>
            </b-col>
            <!--   new Category name  -->
            <transition name="fade">
              <b-col
                v-if="addNewCategory"
                cols="12"
                class="d-flex align-items-center"
              >
                <b-col cols="5">
                  <div class="form-label-group">
                    <b-form @submit.prevent>
                      <b-form-group label="نام دسته بندی" label-for="name">
                        <b-form-input
                          id="name"
                          placeholder="بورس یا..."
                          v-model="defaultCreateData.name"
                        />
                      </b-form-group>
                    </b-form>
                  </div>
                </b-col>
                <b-col cols="5">
                  <language-picker
                    @getLanguage="setLanguageId"
                  ></language-picker>
                </b-col>
                <b-col cols="2">
                  <b-button
                    @click="createProductCategory"
                    :disabled="defaultCreateData.languageId ? false : true"
                    variant="success"
                  >
                    <span class="text-nowrap">ساخت</span>
                  </b-button>
                </b-col>
              </b-col>
            </transition>
          </b-row>
        </div>

        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="categories"
          responsive
          :fields="myTableColumns"
          primary-key="id"
          show-empty
          bordered
          striped
          empty-text="موردی موجود نیست!"
        >
          <!-- Column: delete -->

          <template #cell(actions)="data">
            <!-- <div class="d-flex align-items-center" style="gap: 2rem">
              <router-link
                :to="{
                  name: 'pages-posts-categories-edit',
                  params: { id: data.item.blogCategoryId },
                }"
                class="cursor-pointer d-flex flex-row"
              >
                <feather-icon size="20" icon="EditIcon" />
              </router-link>
              <div
                class="cursor-pointer"
                v-b-modal.modal-delete
                @click="showModal(data.item)"
              >
                <feather-icon size="20" icon="TrashIcon" class="text-danger" />
              </div>
            </div> -->
            <div>
              <add-edit-delete-button
                :data="generateObjectForAddEditRemoveButton(data.item)"
                :isBlogCategory="true"
                :routerEditName="'pages-posts-categories-edit'"
                @renderDeleteModal="renderDeleteModal"
                @getBlogCategoryCreateData="renderCreateModal"
              ></add-edit-delete-button>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12">
              <!-- pagination -->
              <div
                class="my-2 d-flex align-items-center justify-content-center"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCount"
                  :per-page="perPage"
                  first-number
                  align="center"
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      style="transform: rotate(180deg)"
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
    <!-- Delete Modal Start -->
    <b-modal
      id="modal-delete"
      centered
      ok-variant="danger"
      title="حذف دسته بندی"
      ok-title="حذف"
      cancelTitle="انصراف"
      @ok="deleteCategory"
    >
      <span>آیا از حذف دسته بندی اطمینان دارید؟</span>
    </b-modal>
    <!-- Delete Modal End -->

    <!-- Create Modal with mainId Start -->
    <b-modal
      id="modal-create-cat"
      centered
      ok-variant="success"
      title="ایجاد دسته بندی جدید"
      ok-title="ایجاد"
      @cancel="setDefaultData"
      @ok="createProductCategory"
      cancelTitle="انصراف"
    >
      <b-container fluid>
        <b-form-row>
          <b-col cols="12">
            <b-form @submit.prevent>
              <b-form-group label="نام دسته بندی" label-for="name">
                <b-form-input
                  id="name"
                  placeholder="بورس یا..."
                  v-model="defaultCreateData.name"
                />
              </b-form-group>
            </b-form>
          </b-col>
        </b-form-row>
      </b-container>
    </b-modal>
    <!-- Create Modal with mainId End -->
  </b-overlay>
</template>

<script>
import {
  BContainer,
  BCard,
  BRow,
  BCol,
  BForm,
  BFormRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
  BModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  BlogCategoryGetAllRequest,
  BlogCategoryCreateRequest,
  BlogCategoryDeleteRequest,
} from "@/libs/Api/BlogCategory";
import LanguagePicker from "../../../components/utils/LanguagePicker.vue";
import AddEditDeleteButton from "@/views/components/utils/AddEditDeleteButton.vue";

export default {
  name: "categories-list",
  title: "دسته بندی های بلاگ",
  data() {
    return {
      isLoading: false,
      categories: null,
      totalCount: null,
      addNewCategory: false,
      tempPage: null,
      currentPage: 1,
      perPage: 10,
      myTableColumns: [
        {
          key: "blogCategoryId",
          label: "شناسه",
        },
        {
          key: "name",
          label: "نام",
        },
        {
          key: "languagePersianTitle",
          label: "زبان دسته بندی",
        },
        {
          key: "actions",
          label: "عملیات",
        },
      ],
      defaultCreateData: {
        isDeleted: false,
        blogCategoryId: 0,
        name: null,
        mainId: "",
        languageId: null,
      },
    };
  },
  watch: {
    currentPage: {
      handler(val) {
        this.getBlogCategories();
      },
    },
  },
  async created() {
    await this.getBlogCategories();
  },
  components: {
    BContainer,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BFormRow,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    LanguagePicker,
    vSelect,
    AddEditDeleteButton,
  },
  methods: {
    setDefaultData() {
      this.defaultCreateData = {
        isDeleted: false,
        blogCategoryId: 0,
        name: null,
        mainId: "",
        languageId: null,
      };
    },
    renderCreateModal(mainId, langId) {
      this.defaultCreateData.mainId = mainId;
      this.defaultCreateData.languageId = langId;
      this.$bvModal.show("modal-create-cat");
    },
    generateObjectForAddEditRemoveButton(categoryDetails) {
      return {
        id: categoryDetails.blogCategoryId,
        languageId: categoryDetails.languageId,
        otherLanguages: categoryDetails.otherLanguages || [],
        mainId: categoryDetails.mainId,
      };
    },
    setLanguageId(language) {
      this.defaultCreateData.languageId = language.languageId;
    },
    async getBlogCategories() {
      try {
        this.isLoading = true;
        let _this = this;

        let blogCategoryGetAllRequest = new BlogCategoryGetAllRequest(_this);
        blogCategoryGetAllRequest.setRequestParam({
          count: _this.perPage,
          pageNumber: _this.currentPage,
        });
        await blogCategoryGetAllRequest.fetch(
          function (response) {
            if (response.isSuccess) {
              _this.categories = response.data.blogCategories;
              _this.totalCount = response.data.blogCategoriesCount;
            }
          },
          function (content) {
            console.log(content);
          }
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async createProductCategory() {
      try {
        this.isLoading = true;
        let _this = this;
        let blogCategoryCreateRequest = new BlogCategoryCreateRequest(_this);
        blogCategoryCreateRequest.setParams(_this.defaultCreateData);
        await blogCategoryCreateRequest.fetch(
          function (response) {
            if (response.isSuccess) {
              _this.$toast({
                component: ToastificationContent,
                position: "bottom-center",
                props: {
                  title: `عملیات موفق`,
                  icon: "CheckIcon",
                  variant: "success",
                  text: `دسته بندی جدید اضافه شد.`,
                },
              });
              _this.defaultCreateData = {
                isDeleted: false,
                blogCategoryId: 0,
                name: null,
                mainId: "",
                languageId: null,
              };
              _this.getBlogCategories();
            }
          },
          function (error) {
            console.log(error);
          }
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteCategory() {
      try {
        this.isLoading = true;
        let _this = this;

        let blogCategoryDeleteRequest = new BlogCategoryDeleteRequest(_this);
        blogCategoryDeleteRequest.setId(_this.tempPage.id);
        await blogCategoryDeleteRequest.fetch(
          function (response) {
            if (response.isSuccess) {
              _this.$toast({
                component: ToastificationContent,
                position: "bottom-center",
                props: {
                  title: `عملیات موفق`,
                  icon: "CheckIcon",
                  variant: "success",
                  text: `دسته بندی جدید حذف شد.`,
                },
              });
              _this.getBlogCategories();
            }
          },
          function (error) {
            console.log(error);
          }
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    renderDeleteModal(page) {
      this.tempPage = JSON.parse(JSON.stringify(page));
      this.$bvModal.show("modal-delete");
    },
  },
};
</script>

<style scoped></style>
