var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"gap":"2px"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{staticClass:"custom-b-margin",class:_vm.data.languageId == 4 || _vm.doesDataIncludes('Fa', _vm.data.otherLanguages)
            ? 'text-success'
            : 'text-danger',attrs:{"icon":_vm.data.languageId == 4 || _vm.doesDataIncludes('Fa', _vm.data.otherLanguages)
            ? 'CheckIcon'
            : 'XIcon',"size":"15"}}),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("FA")]),_c('b-button',{staticClass:"font-bold p-0 text-white bg-primary my-btn p-25",attrs:{"variant":"primary","type":"button","size":"sm"},on:{"click":_vm.decideFaAction}},[_vm._v(" add/edit ")])],1),_c('b-button',{staticClass:"font-bold p-0 text-white my-btn p-25",attrs:{"disabled":_vm.data.languageId != 4 && !_vm.doesDataIncludes('Fa', _vm.data.otherLanguages),"variant":"danger","type":"button","size":"sm"},on:{"click":_vm.deleteFaPage}},[_vm._v(" delete ")])],1),_c('hr',{staticClass:"border-primary"}),_c('div',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"gap":"2px"}},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"font-size":"10px"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"gap":"2px"}},[_c('feather-icon',{staticClass:"custom-b-margin",class:_vm.data.languageId == 6 ||
            _vm.doesDataIncludes('En', _vm.data.otherLanguages)
              ? 'text-success'
              : 'text-danger',attrs:{"icon":_vm.data.languageId == 6 ||
            _vm.doesDataIncludes('En', _vm.data.otherLanguages)
              ? 'CheckIcon'
              : 'XIcon',"size":"15"}}),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("EN")]),_c('b-button',{staticClass:"font-bold p-0 text-white bg-primary my-btn p-25",attrs:{"variant":"primary","type":"button","size":"sm"},on:{"click":_vm.decideEnAction}},[_vm._v(" add/edit ")])],1),_c('b-button',{staticClass:"font-bold p-0 text-white my-btn p-25",attrs:{"disabled":_vm.data.languageId != 6 && !_vm.doesDataIncludes('En', _vm.data.otherLanguages),"variant":"danger","type":"button","size":"sm"},on:{"click":_vm.deleteEnPage}},[_vm._v(" delete ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }