<template>
  <div>
    <div
      class="d-flex align-items-center justify-content-center"
      style="gap: 2px"
    >
      <div class="d-flex align-items-center">
        <feather-icon
          :icon="
            data.languageId == 4 || doesDataIncludes('Fa', data.otherLanguages)
              ? 'CheckIcon'
              : 'XIcon'
          "
          size="15"
          class="custom-b-margin"
          :class="
            data.languageId == 4 || doesDataIncludes('Fa', data.otherLanguages)
              ? 'text-success'
              : 'text-danger'
          "
        />
        <span style="font-size: 12px">FA</span>
        <b-button
          @click="decideFaAction"
          variant="primary"
          type="button"
          class="font-bold p-0 text-white bg-primary my-btn p-25"
          size="sm"
        >
          add/edit
        </b-button>
      </div>
      <b-button
        :disabled="
          data.languageId != 4 && !doesDataIncludes('Fa', data.otherLanguages)
        "
        @click="deleteFaPage"
        variant="danger"
        type="button"
        class="font-bold p-0 text-white my-btn p-25"
        size="sm"
      >
        delete
      </b-button>
    </div>
    <hr class="border-primary" />
    <div
      class="d-flex align-items-center justify-content-center"
      style="gap: 2px"
    >
      <div class="d-flex align-items-center" style="font-size: 10px">
        <div
          class="d-flex align-items-center justify-content-center"
          style="gap: 2px"
        >
          <feather-icon
            :icon="
              data.languageId == 6 ||
              doesDataIncludes('En', data.otherLanguages)
                ? 'CheckIcon'
                : 'XIcon'
            "
            size="15"
            class="custom-b-margin"
            :class="
              data.languageId == 6 ||
              doesDataIncludes('En', data.otherLanguages)
                ? 'text-success'
                : 'text-danger'
            "
          />
          <span style="font-size: 12px">EN</span>
          <b-button
            @click="decideEnAction"
            variant="primary"
            type="button"
            class="font-bold p-0 text-white bg-primary my-btn p-25"
            size="sm"
          >
            add/edit
          </b-button>
        </div>
        <b-button
          :disabled="
            data.languageId != 6 && !doesDataIncludes('En', data.otherLanguages)
          "
          @click="deleteEnPage"
          variant="danger"
          type="button"
          class="font-bold p-0 text-white my-btn p-25"
          size="sm"
        >
          delete
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["renderDeleteModal", "getBlogCategoryCreateData"],
  props: {
    data: {
      type: Object,
      required: true,
    },
    routerCreateName: {
      type: String, // Create Page of entity name in router
      required: false, //Only required if isBlogCategory props is false
    },
    routerEditName: {
      type: String, // Edit Page of entity name in router
      required: false, //Only required if isBlogCategory props is false
    },
    isBlogCategory: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    deleteFaPage() {
      if (this.data.languageId == 4) {
        this.renderDeleteModal({ id: this.data.id });
      } else if (this.doesDataIncludes("Fa", this.data.otherLanguages)) {
        this.renderDeleteModal({
          id: this.findIdInOtherLanguages("Fa", this.data.otherLanguages),
        });
      }
    },
    deleteEnPage() {
      if (this.data.languageId == 6) {
        this.renderDeleteModal({ id: this.data.id });
      } else if (this.doesDataIncludes("En", this.data.otherLanguages)) {
        this.renderDeleteModal({
          id: this.findIdInOtherLanguages("En", this.data.otherLanguages),
        });
      }
    },
    renderDeleteModal(obj) {
      this.$emit("renderDeleteModal", obj);
    },
    decideFaAction() {
      if (this.data.languageId == 4) {
        this.redirectToEdit(this.data.id);
      } else if (this.doesDataIncludes("Fa", this.data.otherLanguages)) {
        this.redirectToEdit(
          this.findIdInOtherLanguages("Fa", this.data.otherLanguages)
        );
      } else {
        this.redirectToCreate(this.data.mainId, 4);
      }
    },
    decideEnAction() {
      if (this.data.languageId == 6) {
        this.redirectToEdit(this.data.id);
      } else if (this.doesDataIncludes("En", this.data.otherLanguages)) {
        this.redirectToEdit(
          this.findIdInOtherLanguages("En", this.data.otherLanguages)
        );
      } else {
        this.redirectToCreate(this.data.mainId, 6);
      }
    },
    doesDataIncludes(preRoute, data) {
      if (data) {
        let selected = data.find((item) => {
          if (item.preRoute == preRoute) {
            return item;
          }
        });
        if (selected) {
          return true;
        }
      }
    },
    findIdInOtherLanguages(preRoute, data) {
      let selected = data.find((item) => {
        if (item.preRoute == preRoute) {
          return item;
        }
      });
      if (selected) {
        return selected.objectId;
      }
    },
    redirectToEdit(id) {
      this.$router.push({
        name: this.routerEditName,
        params: {
          id,
        },
      });
    },
    redirectToCreate(mainId, langId) {
      if (!this.isBlogCategory) {
        this.$router.push({
          name: this.routerCreateName,
          params: {
            langId: langId,
            mainId: mainId,
          },
        });
      } else {
        this.$emit("getBlogCategoryCreateData", mainId, langId);
      }
    },
  },
  components: {
    BButton,
  },
};
import { BButton } from "bootstrap-vue";
</script>
<style scoped>
.custom-b-margin {
  margin-bottom: 1px;
}
.my-btn {
  font-size: 13px;
  margin-left: 5px;
}
hr {
  margin: 0 !important;
  padding: 0 !important;
  margin: 3px 0 3px 0 !important;
  border-top: 0 !important;
}
</style>
